<template>
  <div>
    <div v-if="type == 101">
      <div class="compont_p">
        我的采购计划
        <div class="btn flex_c_c" @click="type = 102">添加计划</div>
      </div>
      <div>
        <el-table :data="tableData" class="mt_20">
          <el-table-column prop="title" label="商品名称" align="center" />
          <el-table-column prop="manufacturer" label="生产厂家" align="center" />
          <el-table-column prop="specification" label="药品规格" align="center" />
          <el-table-column prop="price" label="药品单价" align="center" />
          <el-table-column prop="number" label="计划数量" align="center" />
          <el-table-column prop="time_str" label="有效期" align="center" />
          <el-table-column prop="reply_status" label="回复状态" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.reply_status == 101 ? "已回复" : "未回复" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="check flex_c_c c_p" @click="handleClick(scope.row)">查看</div>
            </template>
          </el-table-column>
        </el-table>
        <pagination />
      </div>
    </div>

    <div v-if="type == 102">
      <div class="compont_p">
        添加采购计划
        <div class="btn flex_c_c btn1" @click="back">返回上级</div>
      </div>
      <div class="form_wrap">
        <el-form :inline="true" :model="form" label-width="100px">
          <el-form-item label="药品名称：">
            <el-input v-model="form.title" placeholder="药品名称"></el-input>
          </el-form-item>
          <el-form-item label="生产厂家：">
            <el-input v-model="form.manufacturer" placeholder="生产厂家"></el-input>
          </el-form-item>
          <el-form-item label="药品规格：">
            <el-input v-model="form.specification" placeholder="药品规格"></el-input>
          </el-form-item>
          <el-form-item label="药品单价：">
            <el-input v-model="form.price" placeholder="药品单价"></el-input>
          </el-form-item>
          <el-form-item label="计划数量：">
            <el-input v-model="form.number" placeholder="计划数量"></el-input>
          </el-form-item>
          <!-- <el-form-item label="计划有效期：">
            <el-date-picker style="width: 200px" v-model="form.time" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item> -->
          <div>
            <el-form-item label="药品图片：">
              <upload :limit="4" @successUrl="successUrl" @deleteImage="deleteImage"></upload>
            </el-form-item>
          </div>
          <el-form-item label="备注：">
            <el-input type="textarea" style="width: 520px" :rows="5" v-model="form.note"></el-input>
          </el-form-item>
        </el-form>
        <div class="sub_btn flex_c_c" @click="onSubmit">提交采购计划</div>
      </div>
    </div>

    <el-dialog title="详情" :visible.sync="dialogVisible">
      <el-descriptions :column="1">
        <el-descriptions-item label="商品名称">{{ info.title }}</el-descriptions-item>
        <el-descriptions-item label="生产厂家">{{ info.manufacturer }}</el-descriptions-item>
        <el-descriptions-item label="药品规格">{{ info.specification }}</el-descriptions-item>
        <el-descriptions-item label="药品图片" v-if="info.image_arr && info.image_arr.length">
          <div v-for="item in info.image_arr" :key="item.id">
            <el-image style="width: 100px; height: 100px" class="mr_10" :fit="cover" :src="item.url" :preview-src-list="srcList"> </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="药品数量"> {{ info.number }} </el-descriptions-item>
        <el-descriptions-item label="药品价格"> ￥{{ info.price }} </el-descriptions-item>
        <el-descriptions-item label="有效期"> {{ info.time_str }} </el-descriptions-item>
        <el-descriptions-item label="备注"> {{ info.note }} </el-descriptions-item>
        <el-descriptions-item label="回复状态"> {{ info.reply_status == 101 ? "已回复" : "未回复" }} </el-descriptions-item>
        <el-descriptions-item label="回复内容"> {{ info.reply }} </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import upload from "@/components/upload.vue";
export default {
  components: {
    pagination,
    upload,
  },
  data() {
    return {
      type: 101,
      tableData: [],
      form: {
        title: "", //商品名称
        manufacturer: "", //生产企业
        specification: "", //规格
        price: "", //价格
        number: "", //数量
        time: "", //有效期时间戳
        image_arr: [], //图片id
        note: "", //备注
      },
      dialogVisible: false,
      info: {},
      srcList: [],
    };
  },
  created() {
    this.getDemands();
  },
  methods: {
    getDemands() {
      this.$api("account.getDemands").then((res) => {
        this.tableData = res.data;
      });
    },
    successUrl(data) {
      this.form.image_arr.push({ id: data.id });
    },
    deleteImage(file) {
      let index = 0;
      this.form.image_arr.forEach((e, i) => {
        if (e.id == file.id) index = i;
      });
      this.form.image_arr.splice(index, 1);
    },
    back() {
      this.type = 101;
      this.getDemands();
    },
    handleClick(row) {
      this.$api("account.getDemandsDel", { id: row.id }).then((res) => {
        this.info = res.data;
        this.srcList = this.info.image_arr.map((e) => e.url);
        this.dialogVisible = true;
      });
    },
    onSubmit() {
      let p = {
        ...this.form,
        time: Date.parse(this.form.time) / 1000,
      };
      this.$api("account.addDemands", p).then(() => {
        this.$message.success("添加成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 150px;
    top: 0;
    width: 76px;
    height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}
.check {
  width: 48px;
  height: 30px;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
}
.form_wrap {
  margin-top: 20px;
}
.sub_btn {
  margin-left: 100px;
  width: 104px;
  height: 30px;
  background: #ff4c4c;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
